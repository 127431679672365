import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import VideoCard from '../../../../../components/Quick_Installation/Videos/video-wps-netgear';
export const _frontmatter = {
  "title": "How to set up a Wireless Connection",
  "path": "/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/Video/Netgear/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "How to set up a Wireless Connection",
  "image": "./QI_SearchThumb_WLAN.png",
  "social": "/images/Search/QI_SearchThumb_WLAN.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Wireless_Connection_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-to-set-up-a-wireless-connection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-to-set-up-a-wireless-connection",
        "aria-label": "how to set up a wireless connection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to set up a Wireless Connection`}</h1>
    <h2 {...{
      "id": "netgear",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#netgear",
        "aria-label": "netgear permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Netgear`}</h2>
    <p>{`WPS / WLAN Schnellverbindung bei einem Netgear Router`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoCard mdxType="VideoCard" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      